import React from "react";
//import Layout from "../layout/layout";
//import {Spinner} from 'spin';

const FullPageSpinner = () => {
    return (
        <div/>
    );
}
;

export default FullPageSpinner;
