import React from 'react'
import { useAsync } from 'react-async'
import FullPageSpinner from '../components/fullPageSpinner'
import {navigate} from "gatsby-link"; // We definitely want the gatsby-link version – we need to go exactly to /logout

async function getUser() {
    const response = await fetch("/logic/account/user", {credentials: "same-origin"});
    return await response.json();
}

async function bootstrapAppData() {
    const data = await getUser();
    if (!data) {
        return {user: null}
    }
    const {user} = { user: data };
    window.sessionStorage.setItem('sessionUser', JSON.stringify(data));
    return {
        user,
    }
}

const AuthContext = React.createContext();

function AuthProvider(props) {
      // code for pre-loading the user's information if we have their token in
      // localStorage goes here
    const [firstAttemptFinished, setFirstAttemptFinished] = React.useState(false);
    const isSSR = typeof window === "undefined";
    const stored = isSSR ? null : JSON.parse(window.sessionStorage.getItem('sessionUser'));
    const {
        data = isSSR ? {user: null} : {user: stored},
        error,
        isRejected,
        isPending,
        isSettled,
        reload,
    } = useAsync({
        promiseFn: bootstrapAppData,
    })

    React.useLayoutEffect(() => {
        if (isSettled) {
            setFirstAttemptFinished(true)
        }
    }, [isSettled])

      // 🚨 this is the important bit.
      // Normally your provider components render the context provider with a value.
      // But we post-pone rendering any of the children until after we've determined
      // whether or not we have a user token and if we do, then we render a spinner
      // while we go retrieve that user's information.
      if (!firstAttemptFinished && !isSSR && !stored) {
          if (isPending) {
              if (["/de", "/pl", "/ja", "/en", "/de/about", "/pl/about", "/ja/about", "/en/about"].includes(window.location.pathname.endsWith("/") ? window.location.pathname.substring(0, window.location.pathname.length - 1) : window.location.pathname)) {
                  // Fall through
              } else {
                  return <FullPageSpinner/>
              }
          }
          if (isRejected) {
              return (
                  <div style={{color: 'red'}}>
                      <p>Uh oh... There's a problem. Try refreshing the app.</p>
                      <pre>{error.message}</pre>
                  </div>
              )
          }
      }

      const login = (username, password) => { // make a login request
          // Do a POST fetch request to /logic/login and retrieve the cookie! 🍪
          fetch("/logic/login", {
              method: 'POST', // *GET, POST, PUT, DELETE, etc.
              cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
              credentials: 'same-origin', // include, *same-origin, omit
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({ email: username, password: password, invitationID: window.localStorage.getItem("invite") }) // body data type must match "Content-Type" header
          }).then( (response) => {
              if (!response.ok) {
                  // Get reason and throw proper Error
                  throw Error("Generic Network Error")
              } else {
                  window.localStorage.removeItem("invite")
                  window.location.reload()
              }
          } )
      };
      const register = (name, username, password) => { // register the user
          // Do a POST fetch request to /logic/register and retrieve the cookie! 🍪
          fetch("/logic/register", {
              method: 'POST', // *GET, POST, PUT, DELETE, etc.
              cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
              credentials: 'same-origin', // include, *same-origin, omit
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({ name: name, email: username, password: password, invitationID: window.localStorage.getItem("invite") }) // body data type must match "Content-Type" header
          }).then( (response) => {
              if (!response.ok) {
                  // Get reason and throw proper Error
                  throw Error("Generic Network Error")
              } else {
                  window.localStorage.removeItem("invite")
                  window.location.reload()
              }
          } )
      };
      const logout = () => { // clear the token in localStorage and the user data
          window.sessionStorage.removeItem('sessionUser');
          window.sessionStorage.removeItem('sessionServers');
          navigate("/logout");
      };

      // note, I'm not bothering to optimize this `value` with React.useMemo here
      // because this is the top-most component rendered in our app and it will very
      // rarely re-render/cause a performance problem.
      return (
           <AuthContext.Provider value={{data, login, logout, register}} {...props} />
      )
    }

const useAuth = () => React.useContext(AuthContext);

export {AuthProvider, useAuth}