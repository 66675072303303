import React from 'react'
import { useAuth } from './auth'

const UserContext = React.createContext();

function UserProvider(props) {
    const {
        data: {user},
    } = useAuth();
    return <UserContext.Provider value={user} {...props} />
}

function useUser() {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error(`useUser must be used within a UserProvider`)
    }
    return context
}

const stringForName = (nameComponents) => {
    let string = "";
    if (nameComponents.givenName && nameComponents.middleName && nameComponents.familyName) {
        string = nameComponents.givenName + " " + nameComponents.middleName + " " + nameComponents.familyName;
    } else if (nameComponents.givenName && nameComponents.familyName) {
        string = nameComponents.givenName + " " + nameComponents.familyName;
    } else if (nameComponents.givenName) {
        string = nameComponents.givenName;
    }
    return string;
}

const equalNames = (lhs, rhs) => (lhs.givenName === rhs.givenName && lhs.middleName === rhs.middleName && lhs.familyName === rhs.familyName);

export {UserProvider, useUser, stringForName, equalNames}